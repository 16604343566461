<template>

<app-wrapper class="content">

	<h2>{{ $state.page.name }}</h2>

	<div class="content-date"><i class="fa-regular fa-calendar"></i>{{ $state.page.date | formatDate('Do MMMM YYYY') }}</div>

	<img class="content-image" :src="$state.page.image" v-if="$state.page.image" />

	<app-wrapper :text="true" v-html="$state.page.content" />

	<div class="content-tags" v-if="$state.page.tags.length">

		<span v-for="(tag, index) in $state.page.tags" :key="index">{{ tag }}</span>
	
	</div>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.content {
	flex-grow: 1;
	padding: 64px 0px;
}

.content > h2 {
	text-align: left;
}

.is-tablet .content {
	padding: 40px 0px;
}

.is-mobile .content {
	padding: 30px 0px;
}

.content-image {
	max-width: 100%;
	height: auto;
	background-color: #eee;
	margin: 0px auto;
	display: block;
	margin-bottom: 40px;
}

.content-date {
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #112A45;
	font-weight: 500;
}

.content-date i {
	color: #BBD9CD;
	margin-right: 10px;
	font-size: 24px;
}

.content-tags {
	display: flex;
	align-items: center;
	margin-top: 40px;
}

.content-tags:before {
	content: '';
	display: block;
	background-color: #BBD9CD;
	width: 43px;
	height: 43px;
	margin-right: 10px;
	border-radius: 50%;
	background-image: url(~@/assets/tags.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: auto 14px;
}

.content-tags span {
	display: inline-block;
	color: #112A45;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	padding: 0px 6px 0px 5px;
}

.content-tags span:after {
	content: '';
	height: 10px;
	width: 1px;
	background-color: #112A45;
	position: absolute;
	right: 0px;
	top: 50%;
	margin-top: -5px;
}

.content-tags span:last-child:after {
	display: none;
}

</style>
